.extraSubtitle {
    margin-top: -10px;
    margin-bottom: 20px;
    color: rgba(128, 128, 128, 0.75);
    text-transform: lowercase;
    font-size: 0.8em;
}

.subtitle {
    text-transform: lowercase;
    font-size: 0.8em;
    color: rgba(128, 128, 128, 0.75);
}

.homeText {
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.homeText a {
    color: #097fd3;
    text-decoration-style: dotted;
    text-decoration-color: #097fd34b;
}

.homeText a:hover {
    color: #082740;
    text-decoration-style: solid;
    text-decoration-color: #082740;
}

.projectsContainer ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.title {
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

.intro {
    padding: 20px 0 40px;
    line-height: 1.6;
}

@media (max-width: 999px) {
    .projectsContainer ul {
        gap: 40px;
    }
}
