.projectContainer {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px lightgray solid;
    border-radius: 12px;
    overflow: hidden;
}

.date {
    font-size: 0.8em;
    color: grey;
    text-transform: uppercase;
}

.title {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 700;
}

.projectContainer header {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-bottom: 1px solid lightgray;
    line-height: 1.6;
    background: rgba(211, 211, 211, 0.37);
}

.projectImage {
    width: 100%;
    height: auto;
    display: block;
    transform: scale(1);
    transition: all .2s ease-out;
}

.projectDescription {
    position: absolute;
    bottom: 0;
    z-index: 1;
    color: black;
    background: hsla(0, 0%, 100%, 0.7);
    padding: 16px;
    font-size: 1em;
    border-top: 1px solid lightgray;
    line-height: 1.6;
}

.projectContainer:hover img{
    transform: scale(1.05);
    transition: all .2s ease-out;
    width: 100%;
}

.projectContainer:hover header {
    color: #097fd3;
}

.imageContainer {
    overflow: hidden;
}

@media (max-width: 999px) {
    .projectDescription {
        font-size: 0.8em;
        position: relative;
        background: rgba(211, 211, 211, 0.37);
    }
}