.navbar {
    background-color: white;
    position: fixed;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-basis: '100%';
    z-index: 99;
    box-shadow: 0 5px 8px -4px black;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
.navbarItem {
    width: 100%;
    padding: 24px;
}
.hamburgerMenu {
    color: #097fd3;
    cursor: pointer;
    text-align: right;
}
.hamburgerMenu:hover {
    color: black;
}
.navbarTitle {
    font-weight: 700;
    cursor: pointer;
    width: fit-content;
    /* color: #802F93; */
    color: #097fd3;
    text-decoration: none;
    font-family: 'Ubuntu', sans-serif;
    text-transform: uppercase;
}
.navbarTitle:hover {
    color: black
}
.titleContainer {
    flex-basis: 100%;
    text-align: left;
}

.navLinks {
    flex-basis: 100%;
}

.navLinks ul {
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    justify-content: flex-end;
    margin-right: 40px;
}
.navLinks li:first-child {
    border-left: 0;
}
.navLinks li {
    border-left: solid 1px lightgray;
    line-height: 1;
    padding: 0 2em;
}
.navLinks a {
    text-decoration: none;
    /* color: #802F93; */
    color: #097fd3;
}
.navLinks a:hover {
    color: black;
    font-weight: 600;
}
.desktopNavContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-right: 900px;
    margin-top: auto;
    margin-bottom: auto;
}

.bold {
    font-weight: 600;
}