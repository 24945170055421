.themeWrapper {
    padding: 30px 0;
}

.themeWrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.headerContainer {
    display: flex;
    position: relative;
}

.headerTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
}

.headerImg {
    width: 20em;
    height: 5em;
    object-fit: cover;
    position: relative;
}

.headerImg:hover {
    cursor: pointer;
}