.socialContainer {
    display: grid;
    width: max-content;
}

.socialRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.icon {
    position: relative;
    border-radius: 8px;
    padding: 8px;
}

.description {
    font-size: 1em;
    text-transform: lowercase;
    margin: auto 0 auto 40px;
}

.iconWrapper {
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.socialWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.iconWrapper:hover {
    /* transform: translateY(-5px); */
    opacity: 0.5;
}