.sidebarContainer {
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

@media screen and (max-width: 600px) {
    .sidebarContainer {
        height: 100%;
        width: 250px;
        position: fixed;
        z-index: 101;
        top: 0;
        right: 0;
        background-color: white;
        overflow-x: hidden;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
    }
}

.sidebarContainer a {
    text-decoration: none;
    display: block;
    transition: 0.3s;
    cursor: pointer;
    color: black;
}

.sidebarContainer a:hover {
    color: #818181; 
}

.slideIn {
    animation: slideIn 0.5s forwards;
    -webkit-animation: slideIn 0.5s forwards;
}

.slideOut {
    animation: slideOut 0.5s forwards;
    -webkit-animation: slideOut 0.5s forwards;
}
    
@keyframes slideIn {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slideIn {
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slideOut {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
}

@-webkit-keyframes slideOut {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(100%); }
}

.closeIconContainer {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

.closeIconContainer:hover {
    color: #818181;
}

.sidebarLinks ul {
    list-style-type: none;
    text-align: left;
    padding: 20px 40px;
}

.sidebarLinks li {
    border-top: dotted 1px lightgray;
    line-height: 2;
    width: 100%;
    text-align: left;
    padding: 10px 0;
}

.sidebarLinks a {
}

.sidebarLinks li:first-child {
    border-top: 0;
    font-weight: 500;
    font-size: 20px;
    color: #097fd3 !important;  
}

.title {
    color: #097fd3 !important;
    font-weight: 700;  
}