.cardContainer {
    background-color: white;
    border-radius: 12px;
    height: 100%;
    box-shadow: 0px 10px 5px rgb(0,0,0,0.5);
    padding: 40px;
}

.title {
    font-size: 2em;
    font-weight: bold;
    padding-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.subtitle {
    font-size: 1.2em;
    padding-bottom: 40px;
    border-bottom: 1px solid #097fd35e;
}

.bodyContainer {
    margin: 40px 0;
}

@media (max-width: 999px) {
    .cardContainer {
        padding: 20px;
    }
    .title {
        text-align: center;
    }
    .subtitle {
        text-align: center;
        padding-bottom: 20px;
    }
    .bodyContainer {
        margin: 20px 0;
    }
}