.backdrop {
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    animation-name: fadeIn;
    animation-duration: 2s;
}

.fadeIn {
    animation: fadeIn 0.5s forwards;
    -webkit-animation: fadeIn 0.5s forwards;
}

.fadeOut {
    animation: fadeOut 0.5s forwards;
    -webkit-animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
    0% {opacity: 0; }
    100% { opacity: 0.5; }
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0; }
    100% { opacity: 0.5; }
}

@keyframes fadeOut {
    0% { opacity: 0.5; }
    100% { opacity: 0; }
}

@-webkit-keyframes fadeOut {
    0% { opacity: 0.5; }
    100% { opacity: 0; }
}